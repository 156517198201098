

















import orderBy from 'lodash/orderBy'

import { Component, Prop, Vue } from 'vue-property-decorator'

import ConnectionLane from './partials/ConnectionLane.vue'

import { ProjectResource }           from '@/models/projects/ProjectResource'
import { ProcessResource } from '@/models/process/ProcessResource'
import {ProposalSummaryCollectionResource} from '@/models/proposals/ProposalSummaryCollectionResource'

@Component({
  components: {
    ConnectionLane
  }
})
export default class Connections extends Vue {

  @Prop()
  private readonly project!: ProjectResource

  private selectedId: number | null = null
  private selectedIds: number[] | null = null

  private get processes(): ProcessResource[] {
    return this.project.processes
  }

  private get orderedProcesses(): ProcessResource[] {
    if (this.processes.length > 0) {
      return orderBy(this.processes, 'order')
    }

    return []
  }

  private selectProposal(proposal: ProposalSummaryCollectionResource): void {
    if (this.selectedId === proposal.id) {
      this.selectedId = null
      this.selectedIds = []
    } else {
      this.selectedId = proposal.id
      this.selectedIds = proposal.connected_ids
    }
  }

}
