















import { Location } from 'vue-router'
import { Component, Vue, Prop } from 'vue-property-decorator'

import { ProposalSummaryCollectionResource } from '@/models/proposals/ProposalSummaryCollectionResource'

@Component
export default class ProposalSummary extends Vue {
  @Prop()
  private data!: ProposalSummaryCollectionResource

  @Prop({ default: false })
  private canNavigate!: boolean

  @Prop({ default: false })
  private isSelected!: boolean

  @Prop({ default: false })
  private isConnected!: boolean

  @Prop({ default: false })
  private notSelected!: boolean

  private get routerLink(): Location {
    return {
      name: 'projects-detail-proposal-detail',
      params: {
        project_id: this.data.project_id.toString(),
        process_id: this.data.process_id.toString(),
        proposal_id: this.data.id.toString(),
      },
    }
  }

  private select(): void {
    this.$emit('select', this.data)
  }
}
