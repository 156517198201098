












import { Component, Vue, Prop } from 'vue-property-decorator'
import { ProcessResource } from '@/models/process/ProcessResource'

@Component
export default class AddPlaceholder extends Vue {
  @Prop()
  private size!: string

}
